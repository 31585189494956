<template>
  <b-row class="m-5">
    <b-col cols="12">
      <span>Inactive</span>
      <b-form-checkbox
        id="pwdSwitch"
        v-model="user.state"
        name="check-button"
        class="ml-2 custom-control-success"
        switch
        inline
      >
        <span>Active</span>
      </b-form-checkbox>
      <b-button variant="danger" type="button" @click.prevent="onDeleted">
        Change
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from 'vuex'
import {  BRow, BCol, BButton,  BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormCheckbox
  },
  computed: {
    ...mapState({ user: state => state.userStore.user })
  },
  methods:{
    onDeleted() {
      this.$store.dispatch('userStore/setState', { _id: this.user._id, state: this.user.state })
        .then(() => {
          this.$noti.success('User State Changed!')
          this.$emit('onDeleted', true)
        })
        .catch(err => this.$noti.error(`Ha ocurrido un error: ${err}`))
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
